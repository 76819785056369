<template>
  <div class="login_container">
    <el-menu
      :default-active="activeIndex2"
      mode="horizontal"
      @select="handleSelect"
      background-color="#00FFFF"
      text-color="#fff"
      active-text-color="#000000"
    >
      <el-menu-item index="1"></el-menu-item>
      <el-menu-item index="3"></el-menu-item>
    </el-menu>
    <div class="register">
      <el-form
        ref="registerForm"
        :model="registerForm"
        :rules="registerRules"
        class="register-form"
      >
        <h3 class="title">xun综合系统</h3>
        <el-form-item prop="userName">
          <el-input
            v-model="registerForm.userName"
            type="text"
            auto-complete="off"
            placeholder="账号"
            prefix-icon="el-icon-user-solid"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            v-model="registerForm.password"
            type="password"
            auto-complete="off"
            placeholder="密码"
            prefix-icon="el-icon-search"
            @keyup.enter.native="handleRegister"
          ></el-input>
        </el-form-item>
        <el-form-item prop="confirmPassword">
          <el-input
            v-model="registerForm.confirmPassword"
            type="password"
            auto-complete="off"
            placeholder="确认密码"
            prefix-icon="el-icon-search"
            @keyup.enter.native="handleRegister"
          ></el-input>
        </el-form-item>
        <el-form-item prop="phone">
          <el-input v-model="registerForm.phone" placeholder="手机号" prefix-icon="el-icon-search" />
        </el-form-item>
        <el-form-item prop="isAuthorize">
          <el-checkbox
            class="checkbos_class"
            v-model="registerForm.isAuthorize"
            :checked="registerForm.isAuthorize===1? true:false"
            :true-label="1"
            :false-label="2"
          >我已经阅读并同意用户协议</el-checkbox>
        </el-form-item>
        <!-- <el-form-item prop="code" v-if="captchaEnabled">
        <el-input
          v-model="registerForm.code"
          auto-complete="off"
          placeholder="验证码"
          style="width: 63%"
          @keyup.enter.native="handleRegister"
        >
        </el-input>
        <div class="register-code">
          <img :src="codeUrl" @click="getCode" class="register-code-img" />
        </div>
        </el-form-item>-->
        <el-form-item style="width:100%;">
          <el-button
            :loading="loading"
            size="medium"
            type="primary"
            style="width:100%;"
            @click.native.prevent="handleRegister"
          >
            <span v-if="!loading">注 册</span>
            <span v-else>注 册 中...</span>
          </el-button>
          <div style="float: right;">
            <router-link class="link-type" :to="'/login'">使用已有账户登录</router-link>
          </div>
        </el-form-item>
      </el-form>
      <!--  底部  -->
      <div class="el-register-footer">
        <span>湘ICP备18025333号-1 |湘ICP备18025333号</span>
      </div>
    </div>
  </div>
</template>

<script>
import { doLogin, register, loginOut, test } from "@/api/user/user";
export default {
  name: "Register",
  data() {
    const equalToPassword = (rule, value, callback) => {
      if (this.registerForm.password !== value) {
        callback(new Error("两次输入的密码不一致"));
      } else {
        callback();
      }
    };
    var validateAgree = (rule, value, callback) => {
      if (value == 1) {
        callback();
      } else {
        callback(new Error("请勾选同意协议"));
      }
    };
    return {
      activeIndex2: "1",
      codeUrl: "",
      registerForm: {
        userName: "",
        password: "",
        confirmPassword: "",
        code: "",
        uuid: "",
        phone: ""
      },
      registerRules: {
        // phone: [
        //   { required: true, message: "请输入手机", trigger: "blur" },
        //   {
        //     pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
        //     message: "请输入正确的号码格式",
        //     trigger: "change"
        //   }
        // ],
        userName: [
          { required: true, trigger: "blur", message: "请输入您的账号" },
          {
            min: 2,
            max: 20,
            message: "用户账号长度必须介于 2 和 20 之间",
            trigger: "blur"
          }
        ],
        password: [
          { required: true, trigger: "blur", message: "请输入您的密码" },
          {
            min: 5,
            max: 20,
            message: "用户密码长度必须介于 5 和 20 之间",
            trigger: "blur"
          }
        ],
        confirmPassword: [
          { required: true, trigger: "blur", message: "请再次输入您的密码" },
          { required: true, validator: equalToPassword, trigger: "blur" }
        ],
        // code: [{ required: true, trigger: "change", message: "请输入验证码" }],
        isAuthorize: [
          {
            required: true,
            validator: validateAgree,
            trigger: "blur"
          }
        ]
      },
      loading: false,
      captchaEnabled: true
    };
  },
  created() {},
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    handleRegister() {
      this.$refs.registerForm.validate(valid => {
        if (valid) {
          this.loading = true;
          register(this.registerForm)
            .then(res => {
              //   this.resetLoginForm = {};
              //   const userName = this.registerForm.userName;
              //   this.$alert(
              //     "<font color='red'>恭喜你，您的账号 " +
              //       userName +
              //       " 注册成功！</font>",
              //     "系统提示",
              //     {
              //       dangerouslyUseHTMLString: true,
              //       type: "success"
              this.$router.push("/login");
              //     }
              //   )

              // .then(() => {
              //   this.$router.push("/login");
              // })
              // .catch(() => {});
            })
            .catch(() => {
              this.loading = false;
            });
        }
      });
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss">
.register {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-image: url("../assets/bg.jpg");
  background-size: cover;
}
.title {
  margin: 0px auto 30px auto;
  text-align: center;
  color: #707070;
}
// background: #ffffff;
.register-form {
  border-radius: 6px;
  background-color: rgba(221, 234, 191, 0.5);
  width: 400px;
  padding: 25px 25px 5px 25px;
  .el-input {
    height: 38px;
    input {
      height: 38px;
    }
  }
  .input-icon {
    height: 39px;
    width: 14px;
    margin-left: 2px;
  }
}
.register-tip {
  font-size: 13px;
  text-align: center;
  color: #bfbfbf;
}
.register-code {
  width: 33%;
  height: 38px;
  float: right;
  img {
    cursor: pointer;
    vertical-align: middle;
  }
}
.el-register-footer {
  height: 40px;
  line-height: 40px;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #fff;
  font-family: Arial;
  font-size: 12px;
  letter-spacing: 1px;
}
.register-code-img {
  height: 38px;
}
</style>
