// import request from '@/utils/request'
import { getAction, postAction } from '@/api/common/request'
const baseURL = 'xun/common/user';

const doLogin = (params) => postAction(baseURL + '/doLogin', params)

const register = (params) => postAction(baseURL + '/register', params)

const loginOut = (params) => getAction(baseURL + '/loginOut', params)

const test = (params) => getAction(baseURL + '/test', params)


export {
    doLogin,
    register,
    loginOut,
    test
}